@import '/src/component/styles/mixin';

.subscribe-box {
    width: 28rem;
    height: 20rem;
    border: 1px solid #e2e2e2;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    padding: 1.4rem;
    
    box-sizing: border-box;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    // background-color: var(--sict-light-gray);
    text-align: center;
    user-select: none;
}
.subscribe-box > p {
    margin: 0;
    height: 3rem;
}
.subscribe-box > .title {
    font-weight: bold;
    font-size: 1.8rem;
    padding-bottom: 0.4rem;
    border-bottom: 1px solid #e4e4e4;
    margin-bottom: 0.8rem;
}
.subscribe-box > .keyword {
    font-weight: bold;
    color: #0c0c0c;
}

.subscribe-box > .btn-box {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: auto;
}
.subscribe-box > .btn-box > button {
    height: 3.4rem;
    border: none;
    margin-top: 0.4rem;
    font-size: 1.4rem;
    font-weight: bold;
    color: white;
    border-radius: 0.5rem;
    transition: all ease 0.4s 0s;
}

.subscribe-box > .btn-box > .detail-btn {
    background: #00317B;
    color: #ffffff;
    width: 80%;
    margin-right: 1rem;
}
.subscribe-box > .btn-box > .detail-btn:hover {
    cursor: pointer;
    background-color: var(--sict-blue);
    color: #ffffff;
    transition: all ease 0.4s 0s;
}

.subscribe-box > .btn-box > .cancel-btn {
    background-color: #585858;
    color: #ffffff;
    width: 50%;
}
.subscribe-box > .btn-box > .cancel-btn:hover {
    cursor: pointer;
    background-color: #3b3b3b;
    transition: all ease 0.4s 0s;
}
@include short-mobile {
    .subscribe-box {
        width: 22rem;
    }
    .subscribe-box > .title {
        font-size: 1.6rem;
    }
    .subscribe-box > p {
        font-size: 1.4rem;
    }
    .subscribe-box > .btn-box > button {
        font-size: 1.2rem;
    }
}
@include tablet {
    .subscribe-box {
        width: 30rem;
    }
}