@import '/src/component/styles/mixin';

.offer-board-page {
    padding: 1.2rem 2.4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.offer-board-page > .intro {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    // height: 15rem;
    width: 90%;
    text-align: left;
}

.offer-board-page > .intro > .title {
    font-size: 3rem;
}
.offer-board-page > .intro > .description {
    font-size: 1.6rem;
    word-break: keep-all;
    font-weight: 500;
    margin-bottom: 0;
}
.offer-board-page > .offer-board-area {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    margin-bottom: 5rem;
}
.offer-board-page > .offer-board-area > button {
    margin: 0 0 0.5rem auto;
}
.offer-board-page > .offer-board-area > table {
    width: 100%;
    margin-bottom: 2rem;
    border-collapse: collapse;
    table-layout: fixed;
    min-width: 60rem;
}
.offer-board-page > .offer-board-area > table > tbody > .board-item:hover > td{
    background-color: #00000010 ;
}

.offer-board-page > .offer-board-area > table > tbody > .board-item > .board-title:hover {
    cursor: pointer;
}

.offer-board-page > .offer-board-area > table > tbody > tr > th {
    font-weight: normal;
    border-top: 1px solid #c3c3c3;
    border-bottom: 1px solid #c3c3c3;
    // color: #535353;
    background: #353942;
    height: 3rem;
    color: #ffffff;
}
.offer-board-page > .offer-board-area > table > tbody > tr > td {
    text-align: center;
    padding: 0.6rem 0;
    word-break: break-all;
}
.offer-board-page > .offer-board-area > table > tbody > tr:nth-child(odd) {
    background: #eff4ff;
}
.offer-board-page > .offer-board-area > table > tbody > tr:nth-child(even) {
    background: #dfe7f5;
}
.offer-board-area > .offer-items-m{
    width: 100%;
    display: flex;
    flex-direction: column;
    >.offer-item-m {
        padding: 1rem 0;
        border-top: 1px solid #c3c3c3;
        &:last-child {
            border-bottom: 1px solid #c3c3c3;
        }
        p {
            margin: 0;
            word-break: break-all;
        }
        >.title {
            font-size: 1.6rem;
            span {
                font-size: 1.2rem;
                color: #5f5f5f;
            }
        }
        >.info {
            display: flex;
            p {
                padding-right: 0.5rem;
                margin-right: 0.5rem;
                font-size: 1.2rem;
                color: #4d4d4d;
                position: relative;
                &:not(:last-child)::after {
                    content: '';
                    width: 1px;
                    height: 1rem;
                    background: #4d4d4d;
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-45%);
                }
            }
        }
        &:hover {
            background: #00000010;
        }
    }
}

.offer-board-page > .offer-board-area > .paging-area {
    height: 2rem;
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.offer-board-page > .offer-board-area > .paging-area > .pages-area {
    margin: 0 1rem;
    display: flex;
    align-items: center;
}
.offer-board-page > .offer-board-area > .paging-area > .pages-area > .pages {
    text-decoration: none;
    text-align: center;
    color: var(--sict-gray);
    width: 2.1rem;
    font-size: 1.6rem;
    // padding: 0 0.5rem;
}
.offer-board-page > .offer-board-area > .paging-area > .pages-area > .pages:hover {
    color: var(--sict-deep-gray);
    font-weight: 600;
}
.offer-board-page > .offer-board-area > .paging-area > .pages-area > .pages.select {
    color: var(--sict-blue);
    font-weight: 600;
}
.offer-board-page > .offer-board-area > .paging-area > .btn {
    height: 100%;
}
.offer-board-page > .offer-board-area > .paging-area > .btn:hover {
    cursor: pointer;
    background: #0000002d;
    border-radius: 1rem;
}


@include short-mobile {
    .offer-board-page {
        padding: 0.75rem 1.5rem;
    }
    .offer-board-page > .intro > .title {
        font-size: 2.6rem;
    }
    .offer-board-page > .intro > .description {
        width: 20rem;
        font-size: 1.2rem;
    }
    .offer-board-page > .offer-board-area {
        width: 100%;
    }
    .offer-board-area > .offer-items-m{
        >.offer-item-m {
            >.info {
                p {
                    padding-right: 0.3rem;
                    margin-right: 0.3rem;
                    font-size: 1rem;
                    &:not(:last-child)::after {
                        content: '';
                        width: 1px;
                        height: 0.7rem;
                        background: #4d4d4d;
                        position: absolute;
                        right: 0;
                        top: 50%;
                        transform: translateY(-30%);
                    }
                }
            }
        }
    }
}
@include mobile {
    .offer-board-page {
        padding: 0.75rem 1.5rem;
    }
    .intro {
        height: 18rem;
    }
    .offer-board-page > .intro > .title {
        font-size: 2.8rem;
    }
    .offer-board-page > .intro > .description {
        width: 30rem;
        font-size: 1.4rem;
    }
    .offer-board-page > .offer-board-area {
        width: 100%;
    }
}
@include tablet {
    .offer-board-page {
        padding: 1.05rem 2.1rem;
    }
}