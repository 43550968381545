@import '/src/component/styles/mixin';

.box-tile {
    display: grid;
    grid-template-columns: repeat(3, auto);
    row-gap: 1rem;
    column-gap: 1rem;
    justify-content: center;
}

@include short-mobile {
    .box-tile {
        grid-template-columns: repeat(1, auto);
    }
}

@include mobile {
    .box-tile {
        grid-template-columns: repeat(1, auto);
    }
}

@include tablet {
    .box-tile {
        grid-template-columns: repeat(2, auto);
    }
}

@include desktop {
}
