@import '/src/component/styles/mixin';

.state-page {
    // width: 100%;
    display: flex;
    justify-content: center;
    padding: 2rem 1rem;
    overflow: auto;
}
.state-page > .state-area {
    width: 100rem;
}
.state-area > .title-area {
    padding-bottom: 1rem;
    border-bottom: 1px solid #c3c3c3;
    margin-bottom: 2rem;
    display: flex;
}
.state-area > .title-area > .title {
    margin: 0;
    font-size: 2rem;
    font-weight: bold;
}

.state-area > .search-panel {
    padding: 1rem;
    box-sizing: border-box;
    border: 1px solid #c3c3c3;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}
.state-area > .search-panel > .serach-items {
    display: flex;
}
.state-area > .search-panel > .serach-items > .search-item {
    margin-right: 3rem;
}
.state-area > .search-panel > .serach-items > .search-item > label {
    font-size: 1.4rem;
}
.state-area > .search-panel > .serach-items > .search-item > select {
    height: 3rem;
    font-size: 1.4rem;
    margin-left: 1rem;
}
.state-area > .search-panel > .search-btn {
    margin-left: auto;
    border: 1px solid #d4d4d4;
    border-radius: 1rem;
    width: 3rem;
    height: 3rem;
    padding: 0.5rem;
    transition: all ease 0.3s 0s;
}
.state-area > .search-panel > .search-btn:hover {
    cursor: pointer;
    background-color: #d4d4d4;
}
.state-area > .search-panel > .search-btn > img {
    width: 100%;
    height: 100%;
}


.state-area > .data-area {
    display: flex;
    flex-direction: row;
}
.state-area > .data-area.mobile {
    flex-direction: column;
}
.state-area > .data-area > div > table {
    width: 100%;
    background-color: #FFFFFF;
    border: 1px solid #c0c0c0;
    border-collapse: collapse;
    table-layout: fixed;
}
.state-area > .data-area > div > table > tbody > tr > th {
    background-color: #f0f0f0;
    border: 1px solid #c0c0c0;
    padding: 0.4rem 1rem;
    font-size: 1.4rem;
}
.state-area > .data-area > div > table > tbody > tr > td {
    font-size: 1.4rem;
    padding: 0.4rem 1rem;
    border: 1px solid #c0c0c0;
    text-align: center;
}
.state-area > .data-area > div > table > tbody > tr > td > .non-member {
    font-size: 1.1rem;
    color: #656565;
}
.state-area > .data-area > div > table > tbody > tr.selected {
    background-color: #d9e2f7 !important;
}
.state-area > .data-area > div > table > tbody > .data-row:hover {
    background-color: #f1f5ff;
}
.state-area > .data-area > .main-data {
    width: 50%;
    padding: 1rem 1rem 1rem 0;
    box-sizing: border-box;
    border-right: 1px solid #d9d9d9;
}
.state-area > .data-area > .main-data > .title-area {
    display: flex;
    align-items: center;
}
.state-area > .data-area > .main-data > .title-area > span {
    margin: 0;
    color: #656565;
    font-size: 1rem;
    margin-left: 1rem;
}
.state-area > .data-area > .main-data > .title-area > select {
    margin-left: auto;
    border: none;
    text-align: right;
    border-bottom: 1px solid #c3c3c3;
    font-size: 1.2rem;
}

.state-area > .data-area > .detail-data {
    width: 50%;
    padding: 1rem 0 1rem 1rem;
    box-sizing: border-box;
    overflow: auto;
}
.state-area > .data-area > .detail-data > .title-area {
    display: flex;
    align-items: center;
}
.state-area > .data-area > .detail-data > .title-area > select {
    margin-left: auto;
    border: none;
    text-align: right;
    border-bottom: 1px solid #c3c3c3;
    font-size: 1.2rem;
}


@include short-mobile {
    .state-page > .state-area {
        width: 100%;
    }
    .state-area > .search-panel > .serach-items {
        flex-direction: column;
    }
    .state-area > .search-panel > .serach-items > .search-item {
        margin-right: 1rem;
    }
    .state-area > .search-panel > .serach-items > .search-item:last-child {
        margin-top: 1rem;
    }
    .state-area > .search-panel > .serach-items > .search-item > label {
        font-size: 1.2rem;
    }
    .state-area > .search-panel > .serach-items > .search-item > select {
        height: 3rem;
        font-size: 1.2rem;
    }
    .state-area > .search-panel > .search-btn {
        width: 2rem;
        height: 2rem;
    }
    .state-area > .data-area {
        flex-direction: column;
    }
    .state-area > .data-area > .main-data {
        width: 100%;
        border-right: none;
        border-bottom: 1px solid #d9d9d9;
        padding: 1rem;
    }
    .state-area > .data-area > .detail-data {
        width: 100%;
        padding: 1rem;
    }
}
@include mobile {
    .state-page > .state-area {
        width: 100%;
    }
    .state-area > .search-panel > .serach-items > .search-item {
        margin-right: 1rem;
    }
    .state-area > .search-panel > .serach-items > .search-item > label {
        font-size: 1.2rem;
    }
    .state-area > .search-panel > .serach-items > .search-item > select {
        height: 3rem;
        font-size: 1.2rem;
    }
    .state-area > .data-area {
        flex-direction: column;
    }
    .state-area > .data-area > .main-data {
        width: 100%;
        border-right: none;
        border-bottom: 1px solid #d9d9d9;
        padding: 1rem;
    }
    .state-area > .data-area > .detail-data {
        width: 100%;
        padding: 1rem;
    }
}