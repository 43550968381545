@import '/src/component/styles/mixin';

.error-page {
    display: flex;
    flex-direction: column;
    // align-items: center;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.error-page > .title-area {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 13rem;
    margin-bottom: 1rem;
    margin-left: 1rem;
}
.error-page > .title-area > img {
    width: 128px;
    height: 128px;
}
.error-page > .title-area > .text-area {
    display: flex;
    margin-left: 4rem;
    align-items: flex-end;
}
.error-page > .title-area > .text-area > .kor {
    font-size: 6rem;
    margin-right: 1rem;
    padding: 2rem 0;
    color: #252525;
}
.error-page > .title-area > .text-area > .en { 
    font-size: 5rem;
    color: var(--sict-gray);
    padding: 2rem 0;    
}

.error-page > .info-area {
    height: 12rem;
    padding: 1rem 0 1rem 3rem;
    border-left: 7px solid #ffc107;
    box-sizing: border-box;
}
.error-page > .info-area > .text-area {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    word-break: keep-all;
}
.error-page > .info-area > .text-area > p {
    margin: 0;
    white-space: break-spaces;
}
.error-page > .info-area > .text-area > .kor {
    font-weight: 600;
}
.error-page > .info-area > .text-area > .en {
    font-weight: 600;
    color: var(--sict-gray);
    font-size: 1.4rem;
}
.error-page > .btn-area {
    margin-top: 2rem;
    text-align: center;
}
.error-page > .btn-area > button {
    background: #ffffff;
    margin: 0 0.6rem;
    height: 3.4rem;
    border: 1px solid var(--sict-gray);
    border-radius: 5px;
    font-weight: 500;
    padding: 0.6rem 1.6rem;
    color: #252525;
}
.error-page > .btn-area > button:hover {
    cursor: pointer;
    font-weight: bold;
    border: 1px solid var(--sict-deep-gray);
}

@include short-mobile {
    .error-page {
        width: 90%;
        align-items: center;
    }
    .error-page > .title-area {
        flex-direction: column;
        height: auto;
        margin: 0;
    }
    .error-page > .title-area > img {
        width: 96px;
        height: 96px;
    }
    .error-page > .title-area > .text-area {
        margin-left: 0;
        margin-bottom: 1rem;
    }
    .error-page > .title-area > .text-area > .kor {
        padding: 0;
        font-size: 4rem;
    }
    .error-page > .title-area > .text-area > .en { 
        padding: 0;
        font-size: 3rem;
    }
    .error-page > .info-area {
        padding: 0.5rem 0 0.5rem 1rem;
        height: auto;
        width: fit-content;
    }
    .error-page > .info-area > .text-area > .kor {
        font-size: 1.4rem;
        margin-bottom: 1rem;
    }
    .error-page > .info-area > .text-area > .en {
        font-size: 1.2rem;
    }
}
@include mobile {
    .error-page {
        width: 90%;
        align-items: center;
    }
    .error-page > .title-area {
        flex-direction: column;
        height: auto;
        margin: 0;
    }
    .error-page > .title-area > img {
        width: 100px;
        height: 100px;
    }
    .error-page > .title-area > .text-area {
        margin-left: 0;
        margin-bottom: 1rem;
    }
    .error-page > .title-area > .text-area > .kor {
        padding: 0;
        font-size: 5rem;
    }
    .error-page > .title-area > .text-area > .en { 
        padding: 0;
        font-size: 4rem;
    }
    .error-page > .info-area {
        padding: 0.5rem 0 0.5rem 1rem;
        height: auto;
        width: fit-content;
    }
    .error-page > .info-area > .text-area > .kor {
        font-size: 1.4rem;
        margin-bottom: 1rem;
    }
    .error-page > .info-area > .text-area > .en {
        font-size: 1.2rem;
    }
}
@include tablet {
    .error-page > .title-area > img {
        width: 100px;
        height: 100px;
    }
    .error-page > .title-area > .text-area {
        margin-left: 2rem;
    }
    .error-page > .title-area > .text-area > .kor {
        font-size: 5rem;
    }
    .error-page > .title-area > .text-area > .en { 
        font-size: 4rem;
    }
    .error-page > .info-area {
        height: 10rem;
        padding: 0.5rem 0 0.5rem 2rem;
    }
    .error-page > .info-area > .text-area > .kor {
        font-size: 1.4rem;
    }
    .error-page > .info-area > .text-area > .en {
        font-size: 1.2rem;
    }
}