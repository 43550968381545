@import '/src/component/styles/mixin';

.boxItem {
    min-width: 33rem;
    width: 33rem;
    height: 33rem;
    background-color: #3c3c3c;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    padding: 3rem;
    box-sizing: border-box;
    border-radius: 2rem;
    user-select: none;
    background-size: contain;
}

.boxItem-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20%;
}
.boxItem-info > p {
    margin: 0;
    color: #ffffff;
}
.boxItem-info > .title {
    font-weight: bold;
    font-size: 2.5rem;
    word-break: keep-all;
    text-align: center;
}
.boxItem-info > .description {
    margin-top: 1rem;
    font-size: 1.4rem;
    word-break: keep-all;
    text-align: center;
}

.boxItem > .btn {
    position: absolute;
    bottom: 10%;
    width: 50%;
    height: 5rem;
    background-color: transparent;
    border: 5px solid #ffffff;
    border-radius: 10px;
    color: #ffffff;
    font-size: 1.4rem;
    font-weight: bold;
    transition: all ease 0.3s 0s;
}
.boxItem > .btn:hover {
    width: 55%;
    height: 5.5rem;
    background-color: #ffffff;
    color: #3c3c3c;
    font-size: 1.4rem;
    cursor: pointer;
    transition: all ease 0.3s 0s;
}

.boxItem > .btn-admin-area {
    position: absolute;
    bottom: 10%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.boxItem > .btn-admin-area > .btn-admin {
    width: 10rem;
    height: 4rem;
    background-color: transparent;
    border: none;
    border-radius: 10px;
    color: #ffffff;
    font-size: 1.4rem;
    font-weight: bold;
    transition: all ease 0.3s 0s;
    background: #1e66a1ea;
}
.boxItem > .btn-admin-area > .btn-admin.delete {
    background: #a7a7abea;
}
.boxItem > .btn-admin-area > .btn-admin:first-child {
    margin-right: 1rem;
}
.boxItem > .btn-admin-area > .btn-admin:hover {
    background-color: #ffffff;
    color: #3c3c3c;
    font-size: 1.4rem;
    cursor: pointer;
    transition: all ease 0.3s 0s;
}

@include short-mobile {
    .boxItem {
        min-width: 20rem;
        width: 20rem;
        height: 20rem;
        padding: 1.5rem;
    }
    .boxItem-info {
        margin-top: 10%;
    }
    .boxItem-info > .title {
        font-weight: bold;
        font-size: 2rem;
    }
    .boxItem-info > .description {
        margin-top: 1rem;
        font-size: 1.2rem;
    }
    .boxItem > .btn {
        width: 50%;
        height: 4rem;
        border: 4px solid #ffffff;
        font-size: 1.2rem;
    }
    .boxItem > .btn:hover {
        width: 55%;
        height: 4rem;
        font-size: 1.4rem;
    }
}
@include mobile {
    .boxItem {
        min-width: 30rem;
        width: 30rem;
        height: 30rem;
    }
}