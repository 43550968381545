@import '/src/component/styles/mixin';

.sign-up-area {
    height: 70rem;
    width: 70rem;
    padding: 5rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    user-select: none;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.sign-up-area > .title {
    text-align: center;
    font-size: 5rem;
    color: var(--sict-deep-gray);
}
.sign-up-area > .description {
    font-size: 2rem;
    margin-bottom: 3rem;
    color: var(--sict-deep-gray);
}
.sign-up-area > .message-area {
    height: 3rem;
    display: flex;
    align-items: flex-end;
    box-sizing: border-box;
    padding-bottom: 0.4rem;
    width: 100%;
}
.sign-up-area > .message-area > .message {
    margin: 0;
    font-size: 1.2rem;
    font-weight: 500;
    width: 100%;
    color: var(--sict-red);
}
.sign-up-area > .password-info {
    width: 100%;
    margin: 0;
    font-size: 1.2rem;
    color: #777777;
}

.sign-up-area > .btn {
    width: 100%;
    height: 3.6rem;
    margin-top: 1rem;
    background: #00317B;
    border: none;
    font-size: 1.4rem;
    font-weight: bold;
    color: white;
    transition: all ease 0.4s 0s;
}
.sign-up-area > .btn:hover {
    cursor: pointer;
    background-color: var(--sict-blue);
    transition: all ease 0.4s 0s;
}

@media screen and (max-height: 700px) {
    .sign-up-area {
        top: 50px;
        left: 50%;
        transform: translateX(-50%);
    }
}

@include short-mobile {
    .sign-up-area {
        width: 100%;
        padding: 2rem;
        top: 50px;
        left: 50%;
        transform: translateX(-50%);
    }
    .sign-up-area > .title {
        white-space: break-spaces;
        font-size: 2.4rem;
        line-height: 3rem;
        margin-bottom: 1rem;
    }
    .sign-up-area > .description {
        font-size: 1.4rem;
        margin-bottom: 0.4rem;
    }
    .sign-up-area > .message-area > .message {
        font-size: 1rem;
    }
    .sign-up-area > .btn {
        font-size: 1.2rem;
    }
    .sign-up-area > .password-info {
        font-size: 1rem;
    }
}
@include mobile {
    .sign-up-area {
        width: 100%;
        padding: 4rem;
        top: 50px;
        left: 50%;
        transform: translateX(-50%);
    }
    .sign-up-area > .title {
        white-space: break-spaces;
        font-size: 3rem;
        line-height: 3rem;
        margin-bottom: 1rem;
    }
    .sign-up-area > .description {
        font-size: 1.6rem;
        margin-bottom: 1rem;
    }
}

@include tablet {
    .sign-up-area {
        top: 10%;
        left: 50%;
        transform: translateX(-50%);
    }
    .sign-up-area > .title {
        font-size: 4rem;
    }
    .sign-up-area > .description {
        font-size: 1.8rem;
    }
}