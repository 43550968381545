@import '/src/component/styles/mixin';



@keyframes typing{
    from { width: 0 }
    to { width: 100%; max-width: fit-content }
}
@keyframes blink {
    50% {
        border-color: transparent;
    }
}
@keyframes popup {
    0% {
        opacity: 0;
        transform: translate3d(0, 100%, 0);
    }
    to {
        opacity: 1;
        transform: translateZ(0);
    }
}
@keyframes popleft {
    0% {
        opacity: 0;
        transform: translateX(-10%);
    }
    to {
        opacity: 1;
        transform: translateX(0%);
    }
}
@keyframes cloud {
    0% {
        transform: translate(-50%, -5px);
    }
    to {
        transform: translate(-50%, -10px);
    }
}

.home-page {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.item-area {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}
.item-area > .item {
    box-sizing: border-box;
    width: 100rem;
    padding: 0 4rem;
    // background-color: #00000017;
    display: flex;
    flex-direction: column;
    white-space: break-spaces;
    word-break: keep-all;
}
.item-area > .item > p {
    margin: 0;
}
.item-area > .item > .title {
    font-size: 3rem;
}
.item-area > .item > .card {
    margin: 2rem 0;
    padding: 3rem;
    background-color: #eff8ff;
    display: flex;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.014) 0px 1px 2px 0px;
    border-radius: 3rem;
}
.item-area > .item > .card > .title-area {
    margin-bottom: 2rem;
}
.item-area > .item > .card > .title-area > .title {
    color: #5d636e;
    margin-bottom: 1rem;
    font-size: 1.4rem;
}
.item-area > .item > .card > .title-area > .description {
    font-size: 1.6rem;
}
.item-area > .item > .card > .title-area > p {
    margin: 0;
}
.item-area > .item > .card > .main {
    display: flex;
    justify-content: center;
}

.item-area.information {
    background: #00317B; 
    position: relative;
    top: -6rem;
}
.item-area > .item.information {
    // margin-top: 1rem;
    padding-top: 6rem;
    padding-bottom: 6rem;
    height: 100vh;
    box-sizing: border-box;
    justify-content: center;
    position: relative;
}
.item-area > .item.information > strong {
    text-align: center;
    font-size: 3rem;
    margin-bottom: 1rem;
}
.item-area > .item.information > p {
    // margin: 0 auto;
    font-weight: 500;
    // font-size: 2rem;
    color: #ffffff;
}
.item-area > .item.information > .scroll-img {
    width: 96px;
    height: 96px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    opacity: 0.6;
    animation: cloud 1s infinite alternate;
}

.item-area > .item.information > .main-text {
    overflow: hidden;
    margin-bottom: 2rem !important;
    position: relative;
    font-size: 3rem ;
    // white-space: nowrap;
    // border-right: solid 5px white;
    // animation: typing forwards 2s, blink 1s step-end infinite;
    // animation-timing-function: steps(40);
}
.item-area > .item.information > .main-text-mobile {
    font-size: 2rem;
    margin-bottom: 2rem !important;
}
.item-area > .item.information > .sub-text {
    position: relative;
    font-size: 2rem;
    // opacity: 0;
    // animation: popleft forwards 3s;
    // animation-delay: 2s;
}

.mobile-scroll {
    display: flex;
    width: 20rem;
    overflow: auto;
    box-sizing: border-box;
    scroll-snap-type: x mandatory;
}
.mobile-scroll::-webkit-scrollbar {
    display: none;
}
.bot-service-info-card {
    padding: 1rem;
    margin-right: 2rem;
    height: 20rem;
    width: 20rem;
    user-select: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 1rem;
    column-gap: 1rem;
    position: relative;
}
.bot-service-info-card:last-child {
    margin-right: 0
}
.bot-service-info-card > p {
    margin: 0;
    color: #ffffff;
    text-align: center;
}
.bot-service-info-card > p:first-child {
    font-weight: bold;
    margin-bottom: 1rem;
}
.bot-service-info-card > .left-btn {
    border: none;
    position: absolute;
    left: -1rem;
}
.bot-service-info-card > .right-btn {
    border: none;
    position: absolute;
    right: -1rem;
}

.item-area > .item > .card > .btn {
    text-align: center;
    margin-top: 2rem;
    user-select: none;
}
.item-area > .item > .card > .btn > button {
    height: 6rem;
    width: 30rem;
    font-size: 1.6rem;
    font-weight: 600;
    // background: #252525;
    background: #00317B;
    color: #ffffff;
    border-radius: 0.5rem;
    border: none;
    transition: all ease 0.1s 0s;
}
.item-area > .item > .card > .btn > button:hover {
    cursor: pointer;
    // background-color: #5d636e4f;
    width: 32rem;
    // height: 7rem;
    // outline: 3px solid #0b365a;
}
.item-area > .item > .card > .btn > button.disable {
    background: #c3c3c3;
}
.item-area > .item > .card > .btn > button.disable:hover {
    cursor: auto;
    outline: none;
}

.item-area.offer-board {
    margin-bottom: 10rem;
}
.item-area.offer-board > .item > p {
    font-size: 2rem;
    font-weight: bold;
    padding: 0 0 1rem 1rem;
}
.item-area.offer-board > .item > .board-area > .btn {
    text-align: center;
    margin-top: 2rem;
    user-select: none;
}


.item-area > .item > .board-area {
    background: #eff4ff;
    border-radius: 1rem;
    // padding: 1rem 3rem;
    padding-bottom: 1rem;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    > .board {
        width: 100%;
        border-collapse: collapse;
        table-layout: fixed;
        th {
            background: #353942;
            color: #ffffff;
            height: 4rem;
        }
        th:first-child {
            border-radius: 1rem 0 0 0;
        }
        th:last-child {
            border-radius: 0 1rem 0 0;
        }
        tr:nth-child(even) {
            background: #dfe7f5;
        }
        tr:hover {
            cursor: pointer;
            background-color: #00000005;
        }
        td {
            text-align: center;
            height: 4rem;
            word-break: break-word;
            &.title{
                text-align: left;
            }
        }
    }
    > .ranking-none {
        height: 15rem;
        display: flex;
        justify-content: center;
        align-items: center;
        > p {
            font-weight: bold;
        }
    }
}
.item-area.offer-board > .item > .board-area > .btn > button {
    height: 4rem;
    width: 30rem;
    font-size: 1.6rem;
    font-weight: 600;
    background: #353942;
    color: #ffffff;
    border-radius: 0.5rem;
    border: none;
    transition: all ease 0.1s 0s;
    &:hover {
        cursor: pointer;
        width: 32rem;
    }
}


.board-detail-dlg > .background {
    cursor: pointer;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #00000060;
    z-index: 10000;
}
.board-detail-dlg > .detail-area {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: fixed;
    width: 60rem;
    height: 50rem;
    top: 50%;
    left: 50%;
    background: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    border-radius: 1rem;
    transform: translate(-50%, -50%);
    z-index: 10001;
}
.board-detail-dlg > .detail-area > .close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 40px;
    &:hover {
        cursor: pointer;
    }
}
.board-detail-dlg > .detail-area > .detail {
    width: 100%;
    height: calc(100% - 7rem);
    padding: 4rem 3rem;
    box-sizing: border-box;
}
.board-detail-dlg > .detail-area > .detail > .title {
    border-bottom: 1px solid #c3c3c3;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    // height: 7rem;
    box-sizing: border-box;
    >.writer {
        margin-top: 0.6rem;
        display: flex;
        align-items: flex-end;
        p {
            margin: 0;
        }
        >.dt {
            color: #808080;
            font-size: 1.4rem;
            margin-left: 1rem;
        }
    }
}
.board-detail-dlg > .detail-area > .detail > .title > p {
    margin: 0;
    font-size: 2rem;
    font-weight: 500;
}
.board-detail-dlg > .detail-area > .detail > .text {
    height: inherit;
    overflow: auto;
    font-size: 12pt;
    >ul {
        margin: 0;
    }
    >p {
        margin: 0;
    }
    >ol {
        margin: 0;
    }
}
.board-detail-dlg > .detail-area > button {
    height: 4rem;
    width: 20rem;
    font-size: 1.6rem;
    font-weight: 600;
    background: #353942;
    color: #ffffff;
    border-radius: 0.5rem;
    border: none;
    transition: all ease 0.1s 0s;
    margin-bottom: 2rem;
}
.board-detail-dlg > .detail-area > button:hover {
    cursor: pointer;
    width: 24rem;
}

@include short-mobile {
    .item-area > .item {
        width: 100%;
    }
    .item-area.information {
        top: -4rem;
    }
    .item-area > .item.information > .scroll-img {
        width: 80px;
        height: 80px;
    }
    .item-area > .item.information > .sub-text {
        font-size: 1.6rem;
        animation-delay: 0s;
    }
    .item-area > .item.information > strong {
        font-size: 2.4rem;
    }
    .item-area > .item.information > p {
        font-size: 1.6rem;
    }
    .item-area > .item > .title {
        font-size: 2rem;
    }
    .item-area > .item > .card > .main {
        flex-direction: column;
        align-items: center;
    }
    .item-area > .item > .card > .btn > button {
        height: 4rem;
        width: 80%;
        font-size: 1.4rem;
    }
    .item-area > .item > .card > .btn > button:hover {
        width: 85%;
    }

    .mobile-scroll {
        width: 12rem;
    }
    .bot-service-info-card {
        margin-right: 1rem;
        margin-bottom: 2rem;
        width: 12rem;
        height: 12rem;
        box-sizing: border-box;
        scroll-snap-align: start;
    }
    .bot-service-info-card > p {
        width: 12rem;
        font-size: 1rem;
    }
    .bot-service-info-card > p:first-child {
        font-size: 1.2rem;
    }
    .bot-service-info-card:last-child {
        margin-bottom: 0;
    }
    .bot-service-info-card > img {
        width: 3rem;
    }
    .bot-service-info-card > .right-btn {
        right: -0.7rem;
    }
    .bot-service-info-card > .left-btn {
        left: -0.7rem
    }

    .item-area.offer-board > .item > .board-area > .btn > button {
        width: 80%;
        font-size: 1.4rem;
        &:hover {
            cursor: pointer;
            width: 85%;
        }
    }
    .board-detail-dlg > .detail-area {
        width: 95%;
    }
    .item-area > .item > .board-area {
        > .board {
            th {
                padding: 0 1rem;
                font-size: 1.4rem;
            }
            td {
                padding: 0 0.5rem;
                font-size: 1.2rem;
            }
        }
    }

}
@include mobile {
    .item-area > .item {
        width: 100%;
    }
    .item-area.information {
        top: -4rem;
    }
    .item-area > .item.information {
        padding-bottom: 30%;
    }
    .item-area > .item.information > .scroll-img {
        width: 80px;
        height: 80px;
        bottom: 15%;
    }
    .item-area > .item.information > .sub-text {
        font-size: 1.6rem;
        animation-delay: 0s;
    }
    .item-area > .item.information > strong {
        font-size: 2.4rem;
    }
    .item-area > .item.information > p {
        font-size: 1.8rem;
    }
    .item-area > .item > .title {
        font-size: 2rem;
    }
    .item-area > .item > .card > .main {
        flex-direction: column;
        align-items: center;
    }
    .bot-service-info-card {
        margin-right: 1rem;
        margin-bottom: 2rem;
        width: 20rem;
        height: 20rem;
        box-sizing: border-box;
        scroll-snap-align: start;
    }
    .bot-service-info-card > p {
        width: 20rem;
    }
    .bot-service-info-card:last-child {
        margin-bottom: 0;
    }
    .item-area > .item > .card > .btn > button {
        height: 5rem;
        width: 80%;
        font-size: 1.4rem;
    }
    .item-area > .item > .card > .btn > button:hover {
        width: 85%;
    }

    .item-area > .item > .board-area {
        > .board {
            th {
                padding: 0 1rem;
                font-size: 1.4rem;
            }
            td {
                padding: 0 0.5rem;
                font-size: 1.4rem;
            }
        }
    }

    .item-area.offer-board > .item > .board-area > .btn > button {
        width: 80%;
        &:hover {
            cursor: pointer;
            width: 85%;
        }
    }

    .board-detail-dlg > .detail-area {
        width: 95%;
        height: 70%;
    }
}
@include tablet {
    .item-area > .item {
        width: 72.8rem;
    }
    .bot-service-info-card {
        width: 15rem;
        height: 15rem;
    }
    .item-area.information {
        top: -4rem;
    }
    .item-area > .item.information > .main-text {
        font-size: 2.1rem;
    }
    .item-area > .item.information > .sub-text {
        font-size: 1.8rem;
    }
}