@import '/src/component/styles/mixin';
.offer-board-btn {
    border: none;
    padding: 0.3rem 1.2rem;
    box-sizing: border-box;
    border-radius: 0.5rem;
    background: #00317b;
    color: #ffffff;
    margin-right: 1rem;
    transition: all ease 0.3s 0s;
}
.offer-board-btn.white {
    background: #ffffff;
    border: 1px solid #00317b;
    color: #00317b;
}
.offer-board-btn:last-child {
    margin-right: 0;
}
.offer-board-btn:hover {
    cursor: pointer;
}
.offer-board-btn.white:hover {
    background: #00317b;
    color: #ffffff;
}

@include short-mobile {
    .offer-board-btn {
        margin-right: 0.5rem;
    }
}
@include mobile {
    .offer-board-btn {
        margin-right: 0.5rem;
    }
}