@import '/src/component/styles/mixin';

.header {
    // background-color: #111111;
    // background-color: #000046;
    background-color: #00317B;
    display: flex;
    flex-direction: row;
    height: 6rem;
    // height: 8rem;
    position: sticky;
    top: 0;
    user-select: none;
    z-index: 10000;
    // transition: all ease 0.5s 0s;
}

.header-logo {
    height: 100%;
    width: 40%;
    display: flex;
    margin-left: 0.5rem;
    flex-direction: row;
    align-items: center;
}
.header-logo > img {
    padding: 1rem 0.5rem;
    height: 100%;
    box-sizing: border-box;
}
.header-logo > img:hover {
    cursor: pointer;
}
.header-logo > img.menu {
    height: 90%;
    width: 5rem;
    object-fit: contain;
}

.header-user {
    color: #ffffff;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 60%;
    
}

.header-user > a {
    text-decoration: none;
    color: #ffffff;
    // color: #1c1c1c;
    margin: 0;
    font-size: 1.6rem;
    padding-right: 1rem;
    margin-right: 1rem;
    border-right: 1px solid #ffffff;
    // border-right: 1px solid #1c1c1c;
}
.header-user > a:last-child {
    border-right: none;
}

.header-user > .user-info {
    height: 3rem;
    display: flex;
    align-items: center;
    margin-right: 1rem;
    position: relative;
}
.header-user > .user-info:hover {
    cursor: pointer;
}
.header-user > .user-info > p {
    text-decoration: none;
    color: #ffffff;
    // color: #1c1c1c;
    margin: 0;
    font-size: 1.6rem;
}
.header-user > .user-info > img {
    height: 70%;
    transition: all ease 0.2s; 
}
.header-user > .user-info > img.open {
    transform: rotate(180deg);
    transition: all ease 0.2s; 
}
.header-user > .user-info > .user-menu {
    opacity: 0;
    visibility: hidden;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    position: absolute;
    top: 100%;
    right: 0;
    padding: 0.5rem 3rem;
    width: 16rem;
    box-sizing: border-box;
    z-index: 9999;
    transition: all ease 0.15s; 
}
.header-user > .user-info > .user-menu.open {
    opacity: 1;
    visibility: visible;
    transition: all ease 0.15s; 
}
.header-user > .user-info > .user-menu > a {
    text-decoration: none;
    padding: 0.5rem 0;
    color: #0c0c0c;
    font-size: 1.4rem;
}
.header-user > .user-info > .user-menu > a:hover {
    font-weight: bold;
    color: var(--sict-blue);
}

// 모바일 헤더
.mobile-header {
    height: 100%;
    display: flex;
    align-items: center;
    width: 3rem;
    margin-right: 1rem;
}
.mobile-header > img {
    height: 70%;
}
.mobile-header > img:hover {
    cursor: pointer;
}

// 모바일 헤더 메뉴
.mobile-header > .mobile-menu {
    position: fixed;
    width: 100%;
    height: 100%;
    background: #0000003d;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    visibility: hidden;
}
.mobile-header > .mobile-menu.open {
    visibility: visible;
}
.mobile-header > .mobile-menu > .menu-area {
    position: absolute;
    top: 0;
    right: -70%;
    width: 70%;
    height: 100%;
    background: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
.mobile-header > .mobile-menu > .menu-area.open {
    right: 0;
    transition: all ease 0.3s 0s;
}
.mobile-header > .mobile-menu > .menu-area > .menu-header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 4rem;
    border-bottom: 1px solid #c4c4c4;
    background: #fbfbfb;

}
.mobile-header > .mobile-menu > .menu-area > .menu-header > img {
    margin-right: 1rem;
    height: 70%;
}
.mobile-header > .mobile-menu > .menu-area > .menu-header > img:hover {
    cursor: pointer;
}
.mobile-header > .mobile-menu > .menu-area > .menu-list {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 2rem 0 2rem 3rem;
}
.mobile-header > .mobile-menu > .menu-area > .menu-list > a {
    text-decoration: none;
    font-size: 1.8rem;
    color: #111111;
    font-weight: 500;
    margin: 0.5rem 0;
}

@include short-mobile {
    .header {
        height: 4rem;
    }
    .header-user > a {
        font-size: 1.2rem;
        padding-right: 0.75rem;
        margin-right: 0.75rem;
    }
    .header-logo > img {
        padding: 1rem 0.5rem;
    }
    .header-logo > img.menu {
        height: 100%;
        width: 4rem;
    }
}

@include mobile {
    .header {
        height: 4rem;
    }
    .header-user > a {
        font-size: 1.4rem;
        padding-right: 0.75rem;
        margin-right: 0.75rem;
    }
    .header-logo > img {
        padding: 0.8rem 0.5rem;
    }
    .header-logo > img.menu {
        height: 100%;
        width: 4rem;
    }
}

@include tablet {
    .header {
        height: 5rem;
    }
    .header-user > a {
        font-size: 1.6rem;
        padding-right: 1rem;
        margin-right: 1rem;
    }
    .header-logo > img {
        padding: 1rem 0.5rem;
    }
    .header-logo > img.menu {
        height: 95%;
        width: 4rem;
    }
}

@include desktop {
}


