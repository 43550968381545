@font-face {
  font-family: 'Noto Sans CJK KR';
  font-style: normal;
  font-weight: 300;
  src: url("static/fonts/NotoSansKR-Light.woff2") format('woff2'),
  url("static/fonts/NotoSansKR-Light.woff") format('woff'),
  url("static/fonts/NotoSansKR-Light.otf") format('truetype')
}

@font-face {
  font-family: 'Noto Sans CJK KR';
  font-style: normal;
  font-weight: normal;
  src: url("static/fonts/NotoSansKR-Regular.woff2") format('woff2'),
  url("static/fonts/NotoSansKR-Regular.woff") format('woff'),
  url("static/fonts/NotoSansKR-Regular.otf") format('truetype')
}


@font-face {
  font-family: 'Noto Sans CJK KR';
  font-style: normal;
  font-weight: 500;
  src: url("static/fonts/NotoSansKR-Medium.woff2") format('woff2'),
  url("static/fonts/NotoSansKR-Medium.woff") format('woff'),
  url("static/fonts/NotoSansKR-Medium.otf") format('truetype')
}

@font-face {
  font-family: 'Noto Sans CJK KR';
  font-style: normal;
  font-weight: bold;
  src: url("static/fonts/NotoSansKR-Bold.woff2") format('woff2'),
  url("static/fonts/NotoSansKR-Bold.woff") format('woff'),
  url("static/fonts/NotoSansKR-Bold.otf") format('truetype')
}
html {
  font-size: 62.5%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Noto Sans CJK KR', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.6rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
#root {
  /* height: 100vh; */
  /* height: calc(var(--vh, 1vh) * 100); */
  display: flex;
  flex-direction: column;
  --sict-blue: #0c497c;
  --sict-red: #e8481a;
  --sict-deep-gray: #57575b;
  --sict-gray: #a7a7ab;
  --sict-light-gray: #f8f9fc;
}

input, textarea, button {
  font-family: -apple-system, BlinkMacSystemFont, 'Noto Sans CJK KR', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}