@import '/src/component/styles/mixin';

.offer-edit-page {
    padding: 1.2rem 2.4rem;
}

.offer-edit-page > .header-area {
    border-bottom: 1px solid #c3c3c3;
    padding-bottom: 1rem;
    margin-bottom: 2rem;
}
.offer-edit-page > .header-area > strong {
    font-size: 2rem;
}
.offer-edit-area > .title-area {
    display: flex;
    flex-direction: column;
    // align-items: center;
    // padding-bottom: 1rem;
    margin-bottom: 1rem;
}
.offer-edit-area > .title-area > .title {
    width: 100%;
    display: flex;
    margin-bottom: 1rem;
}
.offer-edit-area > .title-area > .title > p {
    margin: 0;
    width: 7rem;
}
.offer-edit-area > .title-area > .title > select {
    box-sizing: border-box;
}
.offer-edit-area > .title-area > .title > input {
    // font-size: 3rem;
    // margin: 0;
    width: calc(100% - 7rem);
    box-sizing: border-box;
}

.offer-edit-area > .content-area {
    padding-bottom: 2rem;
    // margin-bottom: 2rem;
}
.offer-edit-area > .content-area > textarea {
    width: 100%;
    height: 40rem;
    resize: none;
    box-sizing: border-box;
    padding: 1rem;
}

.offer-edit-page > .non-member-pw {
    text-align: center;
    margin-bottom: 1rem;
}
.offer-edit-page > .non-member-pw > p {
    margin: 0;
    font-size: 1.1rem;
    color: #5f5f5f;
}

.offer-edit-page > .btn-area {
    display: flex;
    justify-content: center;
    margin-bottom: 5rem;
}