@import '/src/component/styles/mixin';

// 화면 전체
.content {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    padding-top: 1.2rem;
    box-sizing: border-box;
}

// 제목영역
.content > .title-area {
    border-bottom: 1px solid #3C3C3C;
    word-break: keep-all;
    display: block;
    min-height: 13rem;
    width: 100rem;
    margin-bottom: 5rem;
}
.content > .title-area > .title {
    font-size: 4rem;
}
.content > .title-area > .description {
    margin: 0;
    margin-top: 2rem;
    padding-bottom: 2rem;
}

// 서비스 영역
.content > .service-area {
    width: 100rem;
    margin-bottom: 2rem;
}
// 서비스 영역 - 서비스 설명 영역
.service-area > .info {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 1rem 0 5rem 0px;
    border-bottom: 1px dashed #c3c3c3;
}
.service-area > .info > .info-img {
    width: 50%;
    height: 25rem;
    // border: 1px solid;
    text-align: center;
    position: relative;
}
.service-area > .info > .info-img:hover > .img-background {
    opacity: 1;
}
.service-area > .info > .info-img > .img-background {
    cursor: pointer;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #00000060;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    box-sizing: border-box;
    color: #ffffff;
    font-weight: bold;
    font-size: 2rem;
    opacity: 0;
}
.service-area > .info > .info-img > img {
    height: 100%;
    max-width: 100%;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}
.service-area > .info > .info-img > img:hover {
    cursor: pointer;
}
.service-area > .info > .info-text {
    width: 50%;
    margin-left: 2rem;
    word-break: keep-all;
}
.service-area > .info > .info-text > strong {
    font-size: 3rem;
}
.service-area > .info > .info-text > p {
    margin: 0;
    margin-top: 1.4rem;
    white-space: break-spaces;
}
// 서비스 영역 - 서비스 결과 영역
.service-area > .result {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 5rem 0 1rem 0px;
}
.service-area > .result > .result-img {
    width: 50%;
    height: 25rem;
    // border: 1px solid;
    text-align: center;
    position: relative;
}
.service-area > .result > .result-img:hover > .img-background {
    opacity: 1;
}
.service-area > .result > .result-img > .img-background {
    cursor: pointer;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #00000060;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    box-sizing: border-box;
    color: #ffffff;
    font-weight: bold;
    font-size: 2rem;
    opacity: 0;
}
.service-area > .result > .result-img > img {
    height: 100%;
    max-width: 100%;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}
.service-area > .result > .result-img > img:hover {
    cursor: pointer;
}
.service-area > .result > .result-text {
    width: 50%;
    word-break: keep-all;
}
.service-area > .result > .result-text > strong{
    font-size: 3rem;
}
.service-area > .result > .result-text > p {
    margin: 0;
    margin-top: 1.4rem;
    white-space: break-spaces;
}

// 구독영역
.subscription-area {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding-bottom: 5rem;
    padding-top: 3rem;
    width: 100%;
    background: #181818;
}
.subscription-area > strong{
    font-size: 2.4rem;
    margin-bottom: 2rem;
}

.subscription {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}
.subscription > input {
    height: 3.6rem;
    width: 35rem;
    border: none;
    border-bottom: 1px solid #c3c3c3;
    border-radius: 0.5rem;
    font-size: 2rem;
    padding: 0.5rem 1rem;
    margin-bottom: 2rem;
}
.subscription > input:focus {
    outline: none;
}
.subscription > button {
    // width: 60%;
    width: 30rem;
    height: 4rem;
    background-color: #ebebeb;
    border: none;
    font-size: 1.4rem;
    font-weight: bold;
    border-radius: 0.5rem;
    transition: all ease 0.3s 0s;
}
.subscription > button:hover {
    cursor: pointer;
    background-color: #ffffff;
    
    transition: all ease 0.3s 0s;
}

.content > .big-img {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9000;
    
}
.content > .big-img > .big-img-back {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #0000008a;
    z-index: 9900;
}
.content > .big-img > img {
    max-width: 80%;
    max-height: 80%;
    z-index: 9990;
}

@include short-mobile {
    .content {
        // grid-template-columns: 90%;
        // row-gap: 3rem;
        // padding: 0.75rem 1.5rem;
        padding-top: 0.75rem;
    }
    .content > .title-area {
        width: 90%;
        margin-bottom: 3rem;
    }
    .content > .service-area {
        width: 90%;
        margin-bottom: 3rem;
    }
    .service-area > .info {
        flex-direction: column;
        align-items: center;
        margin-bottom: 0;
    }
    .service-area > .info > .info-img {
        width: 100%;
    }
    // .service-area > .info > .info-img > img {
    //     width: 100%;
    // }
    .service-area > .info > .info-text {
        width: 100%;
        margin: 0;
    }
    .service-area > .info > .info-text > p {
        margin-top: 0.4rem;
    }
    .service-area > .result {
        flex-direction: column;
        align-items: center;
        padding-top: 3rem;
    }
    .service-area > .result > .result-img {
        width: 100%;
    }
    // .service-area > .result > .result-img > img {
    //     width: 100%;
    // }
    .service-area > .result > .result-text {
        width: 100%;
        margin: 0;
    }
    .service-area > .result > .result-text > p {
        margin-top: 0.4rem;
    }
    .subscription {
        width: 100%;
    }
    .subscription > input {
        width: 80%;
        height: 1.8rem;
        font-size: 1.4rem;
    }
    .subscription > button {
        width: 80%;
        height: 3rem;
        font-size: 1.2rem;
    }

    .content > .big-img > img {
        max-width: 90%;
        max-height: 90%;
    }
}
@include mobile {
    .content {
        // grid-template-columns: 90%;
        // row-gap: 4rem;
        padding-top: 0.75rem;
    }
    .content > .title-area {
        width: 90%;
        margin-bottom: 3rem;
    }
    .content > .service-area {
        width: 90%;
    }

    .service-area > .info {
        flex-direction: column;
        align-items: center;
    }
    .service-area > .info > .info-img {
        width: 100%;
    }
    // .service-area > .info > .info-img > img {
    //     width: 100%;
    // }
    .service-area > .info > .info-text {
        width: 100%;
        margin: 0;
    }
    .service-area > .info > .info-text > p {
        margin-top: 0.8rem;
    }
    .service-area > .result {
        flex-direction: column;
        align-items: center;
    }
    .service-area > .result > .result-img {
        width: 100%;
    }
    // .service-area > .result > .result-img > img {
    //     width: 100%;
    // }
    .service-area > .result > .result-text {
        width: 100%;
        margin: 0;
    }
    .service-area > .result > .result-text > p {
        margin-top: 0.8rem;
    }
    .subscription {
        width: 100%;
    }
    .subscription > input {
        width: 80%;
        height: 3rem;
        font-size: 1.6rem;
    }
    .subscription > button {
        width: 80%;
        height: 4rem;
        font-size: 1.2rem;
    }
    .content > .big-img > img {
        max-width: 90%;
        max-height: 90%;
    }
}
@include tablet {
    .content {
        padding-top: 1.05rem;
    }
    .content > .title-area {
        width: 70rem;
    }
    .content > .service-area {
        width: 70rem;
    }
    .service-area > .info > .info-img > img {
        width: 100%;
    }
    .service-area > .result > .result-img > img {
        width: 100%;
    }
}