@import '/src/component/styles/mixin';

.CUSTextField {
    width: 100%;
    height: 4rem;
    font-size: 1.4rem;
    padding-inline: 1rem;
    box-sizing: border-box;
    margin-bottom: 1rem;
    border: 2px solid #c0c0c0;
    transition: all ease 0.3s 0s;
}
.CUSTextField:hover {
    border-color: var(--sict-gray);
    transition: all ease 0.3s 0s;
}
.CUSTextField:focus {
    border-color: var(--sict-gray);
    outline: none;
    box-shadow: #c9c9c9a1 0px 0px 0px 3px;
    transition: all ease 0.2s 0s;
}

@include short-mobile {
    .CUSTextField {
        height: 3rem;
        font-size: 1rem;
    }
}