@import '/src/component/styles/mixin';

.footer {
    width: 100%;
    box-sizing: border-box;
    margin-top: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-top: 1px solid #d4d4d4;
    padding: 5rem 1rem;
    align-items: center;
    // padding-top: 5rem;
}
.footer > p {
    margin: 0;
    font-size: 1.2rem;
    color: #5d636e;
}
.footer > p > a {
    text-decoration: underline;
    color: #5d636e;
}

.footer > .info {
    display: flex;
}
.footer > .info > img{
    height: 3rem;
    padding: 0 2rem;
    object-fit: contain;
}
.info-wrap {
    display: flex;
    flex-direction: row;
}
.info-wrap > div {
    float: left;
    margin-right: 7rem;
}
.info-wrap > .contact {
    margin-right: 0;
}
.info-wrap > div > ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}
.info-wrap > div > span {
    margin: 0;
    font-size: 1.2rem;
    line-height: 1.2rem;
    color: #3e648f;
    font-weight: 600;
    display: block;
    letter-spacing: 1px;
    transform: skew(-0.1deg);
    margin-bottom: 1.2rem;
}
.info-wrap > div > ul > li {
    margin: 0;
    font-size: 1.4rem;
    line-height: 1.4rem;
    transform: skew(-0.1deg);
    color: #5d636e;
    font-weight: 400;
    margin-bottom: 0.8rem;
    letter-spacing: -0.3px;
    white-space: break-spaces;
}

@include short-mobile {
    .info-wrap {
        flex-direction: column;
    }
    .footer > p {
        font-size: 1rem;
    }
    .info-wrap > div {
        margin-right: 0;
        margin-bottom: 0.3rem;
        text-align: center;
    }
    .info-wrap > div > span {
        font-size: 1rem;
        margin-bottom: 0.3rem;
    }
    .footer > .info {
        height: 18rem;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .footer > .info > img {
        margin-bottom: 1rem;
        height: 10%;
    }
    .info-wrap > div > ul > li {
        font-size: 1.2rem;
        line-height: 1.2rem;
    }
}
@include mobile {
    .info-wrap {
        flex-direction: column;
    }
    .footer > p {
        font-size: 1rem;
    }
    .info-wrap > div {
        margin-right: 0;
        margin-bottom: 0.3rem;
        text-align: center;
    }
    .info-wrap > div > span {
        font-size: 1rem;
        margin-bottom: 0.3rem;
    }
    .footer > .info {
        height: 18rem;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .footer > .info > img {
        margin-bottom: 1rem;
        height: 10%;
    }
    .info-wrap > div > ul > li {
        font-size: 1.2rem;
        line-height: 1.2rem;
    }
}

@include tablet {
    .footer > .info {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 10rem;
    }
    .footer > .info > img {
        margin-bottom: 1rem;
    }
}

@include desktop {
}