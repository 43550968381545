@import '/src/component/styles/mixin';

.offer-detail-page {
    padding: 1.2rem 2.4rem;
}

.offer-detail-page > .header-area {
    border-bottom: 1px solid #c3c3c3;
    padding-bottom: 1rem;
    margin-bottom: 2rem;
    >strong {
        font-size: 2rem;
    }
    >.btn-area {
        margin-top: 2rem;
        display: flex;
    }
}
.offer-detail-area {
    margin-bottom: 5rem;
    padding: 0 1rem;
}
.offer-detail-area > .title-area {
    display: flex;
    align-items: center;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
}
.offer-detail-area > .title-area > .title > p {
    font-size: 2.4rem;
    margin: 0;
}
.offer-detail-area > .title-area > .title > .writer-area {
    display: flex;
    align-items: flex-end;
}
.offer-detail-area > .title-area > .title > .writer-area > .writer {
    margin: 0 2rem 0 0;
}
.offer-detail-area > .title-area > .title > .writer-area > .write-dt {
    color: #808080;
    font-size: 1.4rem;
    margin: 0;
}
.offer-detail-area > .title-area > .btn-area {
    margin-left: auto;
    display: flex;
    user-select: none;
}
.offer-detail-area > .title-area > .btn-area > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 6rem;
}
.offer-detail-area > .title-area > .btn-area > div:hover {
    cursor: pointer;
    background-color: #c3c3c3;
    border-radius: 1rem;
}
.offer-detail-area > .title-area > .btn-area > div > img {
    width: 32px;
}
.offer-detail-area > .title-area > .btn-area > div > p {
    margin: 0;
    font-size: 1.2rem;
}

.offer-detail-area > .content-area {
    border-bottom: 1px solid #c3c3c3;
    padding-bottom: 2rem;
    margin-bottom: 2rem;
    min-height: 30rem;
    font-size: 12pt;
    >p {
        margin: 0;
    }
    >ul {
        margin: 0;
    }
    >ol {
        margin: 0;
    }
}
.offer-detail-area > .content-area > p {
    margin: 0;
    white-space: break-spaces;
}

.offer-detail-area > .reply-area > .reply-content-area {
    margin-bottom: 1rem;
    padding: 0 1rem;
}
.offer-detail-area > .reply-area > .reply-content-area > .offer-info {
    display: flex;
    margin-bottom: 1rem;
}
.offer-detail-area > .reply-area > .reply-content-area > .offer-info > p {
    margin: 0;
    margin-right: 0.5rem;
    padding-right: 0.5rem;
    font-size: 1.4rem;
}
.offer-detail-area > .reply-area > .reply-content-area > .offer-info > p:first-child {
    border-right: 1px solid #c3c3c3;
}
.offer-detail-area > .reply-area > .reply-content-area > .reply-content {
    padding: 0 1rem 0.4rem 1rem;
}
.offer-detail-area > .reply-area > .reply-content-area > .reply-content > .reply-writer {
    display: flex;
    align-items: flex-end;
}
.offer-detail-area > .reply-area > .reply-content-area > .reply-content > .reply-writer > .writer {
    margin: 0 2rem 0 0;
    font-weight: 500;
}
.offer-detail-area > .reply-area > .reply-content-area > .reply-content > .reply-writer > .write-dt {
    color: #808080;
    font-size: 1.4rem;
    margin: 0;
}
.offer-detail-area > .reply-area > .reply-content-area > .reply-content > .reply-main {
    display: flex;
}
.offer-detail-area > .reply-area > .reply-content-area > .reply-content > .reply-main > .reply-edit {
    width: 100%;
    padding: 1rem;
    border: 1px solid #c3c3c3;
    border-radius: 1rem;
    background: #ffffff;
}
.offer-detail-area > .reply-area > .reply-content-area > .reply-content > .reply-main > .reply-edit > textarea {
    resize: none;
    width: 100%;
    border: none;
    background: transparent;
    padding: 0.5rem 1rem;
    box-sizing: border-box;
}
.offer-detail-area > .reply-area > .reply-content-area > .reply-content > .reply-main > .reply-edit > textarea:focus {
    outline-color: #00317b;
}
.offer-detail-area > .reply-area > .reply-content-area > .reply-content > .reply-main > p {
    margin: 0;
    white-space: break-spaces;
}
.offer-detail-area > .reply-area > .reply-content-area > .reply-content > .reply-main > .btn {
    margin-left: auto;
    opacity: 0;
}
.offer-detail-area > .reply-area > .reply-content-area > .reply-content:hover {
    background: #00000008;
}
.offer-detail-area > .reply-area > .reply-content-area > .reply-content:hover > .reply-main > .btn {
    opacity: 1;
}
.offer-detail-area > .reply-area > .reply-input {
    border: 1px solid #c3c3c3;
    border-radius: 0.5rem;
    padding: 1rem;
    text-align: right;
}
.offer-detail-area > .reply-area > .reply-input > textarea {
    width: 100%;
    resize: none;
    border: none;
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
    box-sizing: border-box;
}
.offer-detail-area > .reply-area > .reply-input > textarea:focus {
    outline-color: #00317b;
}

.offer-detail-page > .non-member-delete-dlg {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30rem;
    padding: 4rem;
    box-sizing: border-box;
    background: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    >.close {
        position: absolute;
        top: 1rem;
        right: 1rem;
        >img {
            width: 3rem;
        }
        &:hover {
            cursor: pointer;
        }
    }
    >.title {
        margin-bottom: 2rem;
    }
    >form {
        display: flex;
        flex-direction: column;
        >input {
            margin-bottom: 1rem;
        }
    }
}
.read-editer-style {
    > .ql-container {
        font-size: 12pt;
        > .ql-editor {
            padding: 0;
        }
    }
}
@include short-mobile {
    .offer-detail-page > .non-member-delete-dlg {
        width: 90%;
    }
}
@include mobile {
    .offer-detail-page > .non-member-delete-dlg {
        width: 90%;
    }
}