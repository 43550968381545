.CUSFileInput {
    border: 1px solid #c3c3c3;
    border-radius: 1rem;
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
    box-sizing: border-box;
}
.CUSFileInput > .file-info {
    margin: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: calc(100% - 12rem);
}
.CUSFileInput > .btn-area {
    margin-left: auto;
    width: 12rem;
    text-align: right;
}
.CUSFileInput > .btn-area > .remove-file {
    text-decoration: none;
    border-radius: 0.5rem;
    padding: 0.2rem 1rem;
    background-color: #f0f0f0;
    color: var(--sict-gray);
    margin-right: 0.5rem;
    user-select: none;
}
.CUSFileInput > .btn-area > .upload-btn {
    padding: 0.2rem 1rem;
    border-radius: 0.5rem;
    background: #1e66a1;
    color: #ffffff;
    user-select: none;
}
.CUSFileInput > .btn-area > .upload-btn:hover {
    cursor: pointer;
    background: var(--sict-blue);
}