@import '/src/component/styles/mixin';

.modify-page {
    padding: 2rem 1rem;
    display: flex;
    flex-direction: column;
}

// 계정 설정
.modify-area {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.modify-area > .title {
    width: 50rem;
    margin: 0;
    font-size: 2rem;
    font-weight: bold;
    padding-bottom: 1rem;
    border-bottom: 1px solid #c3c3c3;
    margin-bottom: 2rem;
}
.modify-area > .title-area {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    width: 50rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #c3c3c3;
    margin-bottom: 2rem;
}
.modify-area > .title-area> .title {
    margin: 0;
    font-size: 2rem;
    font-weight: bold;
}
.modify-area > .title-area> .description {
    margin: 0;
    font-size: 1.2rem;
    color: #acacac;
}
.modify-table {
    width: 50rem;
    border-collapse: collapse;
    table-layout: fixed;
}
.modify-table > label {
    font-size: 1.4rem;
    padding-left: 0.4rem;
}

.modify-area > .modify-btn-area {
    width: 50rem;
    text-align: right;
    margin-top: 1rem;
    margin-bottom: 5rem;
}
.modify-area > .modify-btn-area > .modify-btn {
    height: 4rem;
    width: 100%;
    padding: 0 1rem;
    box-sizing: border-box;
    background: #00317B;
    border: none;
    font-size: 1.4rem;
    font-weight: bold;
    color: white;
    transition: all ease 0.4s 0s;
}
.modify-area > .modify-btn-area > .modify-btn:hover {
    cursor: pointer;
    background-color: var(--sict-blue);
}
.modify-area > .modify-btn-area > .modify-btn.leave {
    background-color: #f1f1f1;
    color: var(--sict-gray);
}
.modify-area > .modify-btn-area > .modify-btn.leave:hover {
    cursor: pointer;
    color: #444444;
}

@include short-mobile {
    .modify-area > .title-area {
        width: 90%;
        flex-direction: column;
        align-items: flex-start;
        justify-content: normal;
    }
    .modify-area > .title-area > .description {
        font-size: 1rem;
    }
    .modify-table {
        width: 90%;
    }
    .modify-table > label {
        font-size: 1.1rem;
    }
    .modify-area > .modify-btn-area {
        width: 90%;
    }
}

@include mobile {
    .modify-area > .title-area {
        width: 90%;
        flex-direction: column;
        align-items: flex-start;
        justify-content: normal;
    }
    .modify-area > .title-area > .description {
        font-size: 1.2rem;
    }
    .modify-table {
        width: 90%;
    }
    .modify-area > .modify-btn-area {
        width: 90%;
    }
}

@include tablet {
}