@import '/src/component/styles/mixin';

.main-area {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}
.main-area > article {
    // width: 100%;
}
.scroll-up {
    position: fixed;
    display: flex;
    bottom: 2rem;
    right: 4rem;
    width: 48px;
    height: 48px;
    opacity: 0;
    visibility: hidden;
    transition: all ease 0.3s 0s;
}
.scroll-up:hover {
    cursor: pointer;
}
.scroll-up.show-btn {
    opacity: 1;
    visibility: visible;
    transition: all ease 0.3s 0s;
}

@include short-mobile {
    .scroll-up {
        bottom: 1rem;
        right: 1rem;
        width: 32px;
        height: 32px;
    }
}
@include mobile {
    .scroll-up {
        bottom: 1rem;
        right: 1rem;
    }
}

@include tablet {
    .scroll-up {
        bottom: 1rem;
        right: 2rem;
    }
}

@include desktop {
}
