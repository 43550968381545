@import '/src/component/styles/mixin';

// 메뉴바
.menu-tree {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 4px 12px;
    display: flex;
    flex-direction: row;
    padding: 0 2rem;
    overflow: auto;
    width: 100%;
    box-sizing: border-box;
    user-select: none;
}
.menu-tree::-webkit-scrollbar {
    width: 5px;
    height: 3px;
}
.menu-tree::-webkit-scrollbar-track {
    background-color: transparent;
}
.menu-tree::-webkit-scrollbar-thumb {
    background-color: #adadad;
    opacity: 0.8;
    border-radius: 3px;
}
.menu-tree > .menu-item {
    height: 3rem;
    padding: 0.6rem 1rem;
    display: flex;
    align-items: center;
}
.menu-tree > .menu-item.selected {
    border-bottom: 2px solid var(--sict-blue);
}
.menu-tree > .menu-item > a {
    text-decoration: none;
    color: #0c0c0c;
    font-weight: 500;
    font-size: 1.4rem;
    white-space: nowrap;
}
.menu-tree > .menu-item.selected > a {
    color: var(--sict-blue);
    font-weight: bold;
}

// 전체페이지
.my-page-area {
    display: flex;
    flex-direction: column;
}
.my-page-area > .menu-content {
    padding: 2rem 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.my-page-area > .menu-content > .subscribe-title {
    width: 88rem;
    margin: 0;
    font-size: 2rem;
    font-weight: bold;
    padding-bottom: 1rem;
    border-bottom: 1px solid #c3c3c3;
    margin-bottom: 2rem;
}
.my-page-area > .menu-content > .subscribe {
    display: grid;
    grid-template-columns: repeat(3, auto);
    row-gap: 2rem;
    column-gap: 2rem;
    justify-content: center;
}
.my-page-area > .menu-content > .subscribe > .none-subscribe {
    grid-column: 1 / 4;
    border: 1px solid #e2e2e2;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    padding: 1.4rem;
    box-sizing: border-box;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    text-align: center;
    user-select: none;
    height: 10rem;
    justify-content: center;
}
.my-page-area > .menu-content > .subscribe > .none-subscribe > p {
    margin: 0;
    font-weight: 600;
}

// 계정 설정
.account {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.account > .title {
    width: 100rem;
    margin: 0;
    font-size: 2rem;
    font-weight: bold;
    padding-bottom: 1rem;
    border-bottom: 1px solid #c3c3c3;
    margin-bottom: 2rem;
}
.user-info-table {
    width: 100rem;
    border-collapse: collapse;
    table-layout: fixed;
}
.user-info-row > th, .user-info-row > td {
    height: 5rem;
    border-top: 1px solid #dadada;
    border-bottom: 1px solid #dadada;
    padding: 0 2rem;
    font-size: 1.4rem;
}
.user-info-row > th {
    width: 18rem;
    background-color: #f5f5f5;
    font-weight: normal;
    text-align: left;
}
.user-info-row > td {
    background-color: #ffffff;
}
.account > .modify-btn-area {
    width: 100rem;
    text-align: right;
    margin-top: 2rem;
}
.account > .modify-btn-area > .modify-btn {
    height: 3rem;
    padding: 0 1rem;
    box-sizing: border-box;
    font-size: 1.2rem;
    border: 1px solid #f5f5f5;
    background-color: #f5f5f5;
    transition: all ease 0.4s 0s;
}
.account > .modify-btn-area > .modify-btn:hover {
    cursor: pointer;
    background-color: #dadada;
}

@include short-mobile {
    .my-page-area > .menu-content > .subscribe-title {
        width: 90%;
    }
    .my-page-area > .menu-content > .subscribe {
        grid-template-columns: repeat(1, auto);
    }
    .account > .title {
        width: 90%;
    }
    .user-info-table {
        width: 90%;
    }
    .user-info-row > th {
        width: 6rem;
        font-size: 1.2rem;
        padding: 0 1rem;
        word-break: keep-all;
    }
    .user-info-row > td {
        font-size: 1.2rem;
        padding: 0 1rem;
        word-break: break-all;
    }
    .account > .modify-btn-area {
        width: 90%;
    }
}

@include mobile {
    .my-page-area > .menu-content > .subscribe-title {
        width: 90%;
    }
    .my-page-area > .menu-content > .subscribe {
        grid-template-columns: repeat(1, auto);
    }
    .account > .title {
        width: 90%;
    }
    .user-info-table {
        width: 90%;
    }
    .user-info-row > th {
        width: 8rem;
        font-size: 1.2rem;
        padding: 0 1.4rem;
    }
    .user-info-row > td {
        font-size: 1.2rem;
        word-break: break-all;
        padding: 0 1.4rem;
    }
    .account > .modify-btn-area {
        width: 90%;
    }
}

@include tablet {
    .my-page-area > .menu-content > .subscribe-title {
        width: 62rem;
    }
    .my-page-area > .menu-content > .subscribe {
        grid-template-columns: repeat(2, auto);
    }
    .account > .title {
        width: 90%;
    }
    .user-info-table {
        width: 90%;
    }
    .account > .modify-btn-area {
        width: 90%;
    }
}