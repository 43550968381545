@import '/src/component/styles/mixin';

.manage-cotent-page {
    padding: 2rem 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.manage-cotent-page > .title-area {
    width: 100rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #c3c3c3;
    margin-bottom: 2rem;
    display: flex;
}
.manage-cotent-page > .title-area > .title {
    margin: 0;
    font-size: 2rem;
    font-weight: bold;
}
.manage-cotent-page > .title-area > button {
    margin-left: auto;
    width: 8rem;
    height: 3rem;
    border: none;
    border-radius: 0.5rem;
    background: #00317B;
    color: #ffffff;
    font-weight: bold;
    transition: all ease 0.4s 0s;
    user-select: none;
}
.manage-cotent-page > .title-area > button:hover {
    cursor: pointer;
    background: #2975b3;
}
.manage-cotent-page > .edit-page {
    width: 100rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.manage-cotent-page > .edit-page > .submit {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-bottom: 1rem;
    margin-bottom: 5rem;
    border-bottom: 1px solid #c3c3c3;
}
.manage-cotent-page > .edit-page > .submit > button {
    width: 8rem;
    height: 3rem;
    margin-left: 1rem;
    border: none;
    font-size: 1.4rem;
    font-weight: bold;
    color: var(--sict-gray);
    border-radius: 0.5rem;
    transition: all ease 0.4s 0s;
    user-select: none;
}
.manage-cotent-page > .edit-page > .submit > button:hover {
    cursor: pointer;
    color: #444444;
}
.manage-cotent-page > .edit-page > .submit > button.save {
    background: #00317B;
    color: #ffffff;
}
.manage-cotent-page > .edit-page > .submit > button.save:hover {
    cursor: pointer;
    background-color: var(--sict-blue);
}

// 썸네일 수정 영역 CSS
.manage-cotent-page > .edit-page > .thumb {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 0 2em;
    box-sizing: border-box;
    padding-bottom: 5rem;
    margin-bottom: 5rem;
    border-bottom: 1px solid #c3c3c3;
}
.manage-cotent-page > .edit-page > .thumb > .edit-area {
    display: flex;
    flex-direction: column;
    margin-left: 5rem;
    width: 100%;
}
.manage-cotent-page > .edit-page > .thumb > .edit-area > label {
    font-weight: bold;
}
.manage-cotent-page > .edit-page > .thumb > .edit-area > input {
    margin-bottom: 2rem;
    height: 4rem;
    // width: 50rem;
    font-size: 1.6rem;
    border: none;
    border-bottom: 1px solid #c3c3c3;
    box-sizing: border-box;
    padding: 1rem 1rem;
    border-radius: 0;    
}
.manage-cotent-page > .edit-page > .thumb > .edit-area > input:focus {
    outline: none;
    border-bottom: 2px solid #c3c3c3;
    // padding: 2rem 1rem;
}

// 상세정보 수정 영역 CSS
.manage-cotent-page > .edit-page > .main {
    width: 100%;
    margin-bottom: 3rem;
}
.manage-cotent-page > .edit-page > .main > .info {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 1rem 0;
    border-bottom: 1px dashed #c3c3c3;
}
.manage-cotent-page > .edit-page > .main > .info > .info-img {
    width: 50%;
    height: 33rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.manage-cotent-page > .edit-page > .main > .info > .info-img > img {
    height: 85%;
    max-width: 100%;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
    margin-bottom: 1rem;
}
.manage-cotent-page > .edit-page > .main > .info > .info-img > input {
    height: 4rem;
}
.manage-cotent-page > .edit-page > .main > .info > .info-text {
    width: 50%;
    margin-left: 2rem;
    word-break: keep-all;
    display: flex;
    flex-direction: column;
}
.manage-cotent-page > .edit-page > .main > .info > .info-text > .title {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}
.manage-cotent-page > .edit-page > .main > .info > .info-text > .title > strong {
    font-size: 3rem;
}
.manage-cotent-page > .edit-page > .main > .info > .info-text > .title > p {
    margin: 0;
    color: var(--sict-deep-gray);
}
.manage-cotent-page > .edit-page > .main > .info > .info-text > textarea {
    margin: 0;
    margin-top: 1.4rem;
    white-space: break-spaces;
    resize: none;
    height: 100%;
    font-size: 1.6rem;
    border-radius: 1rem;

    padding-inline: 1rem;
    box-sizing: border-box;
    margin-bottom: 1rem;
    border: 2px solid #c0c0c0;
    transition: all ease 0.3s 0s;
}
.manage-cotent-page > .edit-page > .main > .info > .info-text > textarea:focus {
    border-color: var(--sict-gray);
    outline: none;
    box-shadow: #c9c9c9a1 0px 0px 0px 3px;
    transition: all ease 0.2s 0s;
}
// 서비스 영역 - 서비스 결과 영역
.manage-cotent-page > .edit-page > .main > .result {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 1rem 0;
}
.manage-cotent-page > .edit-page > .main > .result > .result-img {
    width: 50%;
    height: 33rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.manage-cotent-page > .edit-page > .main > .result > .result-img > img {
    height: 85%;
    max-width: 100%;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
    margin-bottom: 1rem;
}
.manage-cotent-page > .edit-page > .main > .result > .result-text {
    width: 50%;
    word-break: keep-all;
    display: flex;
    flex-direction: column;
    margin-right: 2rem;
}
.manage-cotent-page > .edit-page > .main > .result > .result-text > .title {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}
.manage-cotent-page > .edit-page > .main > .result > .result-text > .title > strong {
    font-size: 3rem;
}
.manage-cotent-page > .edit-page > .main > .result > .result-text > .title > p {
    margin: 0;
    color: var(--sict-deep-gray);
}

.manage-cotent-page > .edit-page > .main > .result > .result-text > textarea {
    margin: 0;
    margin-top: 1.4rem;
    white-space: break-spaces;
    resize: none;
    height: 100%;
    font-size: 1.6rem;
    border-radius: 1rem;

    padding-inline: 1rem;
    box-sizing: border-box;
    margin-bottom: 1rem;
    border: 2px solid #c0c0c0;
    transition: all ease 0.3s 0s;
}
.manage-cotent-page > .edit-page > .main > .result > .result-text > textarea:focus {
    border-color: var(--sict-gray);
    outline: none;
    box-shadow: #c9c9c9a1 0px 0px 0px 3px;
    transition: all ease 0.2s 0s;
}

.manage-cotent-page > .edit-page > .keyword {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    justify-content: center;
    margin-bottom: 5rem;
}
.manage-cotent-page > .edit-page > .keyword > div > input {
    width: 2rem;
    height: 2rem;
}
.manage-cotent-page > .edit-page > .keyword > input {
    margin-top: 1rem;
    width: 20rem;
    height: 4rem;
    font-size: 1.8rem;
    border-radius: 0.5rem;
    border: 1px solid #c3c3c3;
    padding: 0.5rem 1rem;
    box-sizing: border-box;
}
.manage-cotent-page > .edit-page > .keyword > input:focus {
    outline: none;
    border: 2px solid #b8b8b8;
    padding: 0 0.9rem;
}

@include short-mobile {
    .manage-cotent-page {
        padding: 0.75rem 1.5rem;
    }
    .manage-cotent-page > .title-area {
        width: 90%;
    }
    .manage-cotent-page > .edit-page {
        width: 100%;
    }
    .manage-cotent-page > .edit-page > .thumb {
        flex-direction: column;
        align-items: center;
        margin-bottom: 3rem;
    }
    .manage-cotent-page > .edit-page > .thumb > .edit-area {
        margin-left: 0;
        margin-top: 2rem;
        width: 100%;
    }
    .manage-cotent-page > .edit-page > .thumb > .edit-area > input {
        width: 100%;
        height: 3.2rem;
        font-size: 1.2rem;
    }

    .manage-cotent-page > .edit-page > .main > .info {
        flex-direction: column;
        align-items: center;
    }
    .manage-cotent-page > .edit-page > .main > .info > .info-text {
        width: 100%;
        margin-left: 0;
        align-items: center;
    }
    .manage-cotent-page > .edit-page > .main > .info > .info-img {
        width: 100%;
    }
    .manage-cotent-page > .edit-page > .main > .info > .info-text.input {
        height: 30rem;
    }
    .manage-cotent-page > .edit-page > .main > .info > .info-text.input > .title {
        width: 90%;
        justify-content: flex-end;
    }
    .manage-cotent-page > .edit-page > .main > .info > .info-text.input > textarea {
        margin-top: 0;
        width: 90%;
    }

    .manage-cotent-page > .edit-page > .main > .result {
        flex-direction: column;
        align-items: center;
    }
    .manage-cotent-page > .edit-page > .main > .result > .result-text {
        width: 100%;
        margin-left: 0;
        align-items: center;
    }
    .manage-cotent-page > .edit-page > .main > .result > .result-img {
        width: 100%;
    }
    .manage-cotent-page > .edit-page > .main > .result > .result-text.input {
        height: 30rem;
    }
    .manage-cotent-page > .edit-page > .main > .result > .result-text.input > .title {
        width: 90%;
        justify-content: flex-end;
    }
    .manage-cotent-page > .edit-page > .main > .result > .result-text.input > textarea {
        margin-top: 0;
        width: 90%;
    }
}
@include mobile {
    .manage-cotent-page {
        padding: 0.75rem 1.5rem;
    }
    .manage-cotent-page > .title-area {
        width: 90%;
    }
    .manage-cotent-page > .edit-page {
        width: 100%;
    }
    .manage-cotent-page > .edit-page > .thumb {
        flex-direction: column;
        align-items: center;
        margin-bottom: 3rem;
    }
    .manage-cotent-page > .edit-page > .thumb > .edit-area {
        margin-left: 0;
        margin-top: 2rem;
        width: 100%;
    }
    .manage-cotent-page > .edit-page > .thumb > .edit-area > input {
        width: 100%;
        height: 3.6rem;
        font-size: 1.4rem;
    }

    .manage-cotent-page > .edit-page > .main > .info {
        flex-direction: column;
        align-items: center;
    }
    .manage-cotent-page > .edit-page > .main > .info > .info-text {
        width: 100%;
        margin-left: 0;
        align-items: center;
    }
    .manage-cotent-page > .edit-page > .main > .info > .info-img {
        width: 100%;
    }
    .manage-cotent-page > .edit-page > .main > .info > .info-img > img{
        margin-bottom: 1rem;
    }
    .manage-cotent-page > .edit-page > .main > .info > .info-text.input {
        height: 30rem;
    }
    .manage-cotent-page > .edit-page > .main > .info > .info-text.input > .title {
        width: 90%;
        justify-content: flex-end;
    }
    .manage-cotent-page > .edit-page > .main > .info > .info-text.input > textarea {
        margin-top: 0;
        width: 90%;
    }

    .manage-cotent-page > .edit-page > .main > .result {
        flex-direction: column;
        align-items: center;
    }
    .manage-cotent-page > .edit-page > .main > .result > .result-text {
        width: 100%;
        margin-right: 0;
        align-items: center;
    }
    .manage-cotent-page > .edit-page > .main > .result > .result-img {
        width: 100%;
    }
    .manage-cotent-page > .edit-page > .main > .result > .result-img > img {
        margin-bottom: 1rem;
    }
    .manage-cotent-page > .edit-page > .main > .result > .result-text.input {
        height: 30rem;
    }
    .manage-cotent-page > .edit-page > .main > .result > .result-text.input > .title {
        width: 90%;
        justify-content: flex-end;
    }
    .manage-cotent-page > .edit-page > .main > .result > .result-text.input > textarea {
        margin-top: 0;
        width: 90%;
    }
}

@include tablet {
    .manage-cotent-page > .title-area {
        width: 67rem;
    }
    .manage-cotent-page > .edit-page {
        width: 70rem;
    }
    .manage-cotent-page > .edit-page > .thumb > .edit-area {
        margin-left: 2rem;
        width: calc(100% - 32rem);
    }
    .manage-cotent-page > .edit-page > .thumb > .edit-area > input {
        width: 100%;
        height: 3.6rem;
        font-size: 1.4rem;
    }
}

@include desktop {
}