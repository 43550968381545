@import '/src/component/styles/mixin';

.infoService {
    padding: 1.2rem 2.4rem;
}
.infoService > .intro {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 15rem;
}

.infoService > .intro > .title {
    font-size: 3rem;
}
.infoService > .intro > .description {
    font-size: 1.6rem;
    text-align: center;
    word-break: keep-all;
    font-weight: 500;
}


@include short-mobile {
    .infoService {
        padding: 0.75rem 1.5rem;
    }
    .infoService > .intro > .title {
        font-size: 2.6rem;
    }
    .infoService > .intro > .description {
        width: 20rem;
        font-size: 1.2rem;
    }
}
@include mobile {
    .infoService {
        padding: 0.75rem 1.5rem;
    }
    .intro {
        height: 18rem;
    }
    .infoService > .intro > .title {
        font-size: 2.8rem;
    }
    .infoService > .intro > .description {
        width: 30rem;
        font-size: 1.4rem;
    }
}
@include tablet {
    .infoService {
        padding: 1.05rem 2.1rem;
    }
}