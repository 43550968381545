// 메뉴바
.menu-tree {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 4px 12px;
    display: flex;
    flex-direction: row;
    padding: 0 2rem;
    overflow: auto;
    width: 100%;
    box-sizing: border-box;
    user-select: none;
}
.menu-tree::-webkit-scrollbar {
    width: 5px;
    height: 3px;
}
.menu-tree::-webkit-scrollbar-track {
    background-color: transparent;
}
.menu-tree::-webkit-scrollbar-thumb {
    background-color: #adadad;
    opacity: 0.8;
    border-radius: 3px;
}
.menu-tree > .menu-item {
    height: 3rem;
    padding: 0.6rem 1rem;
    display: flex;
    align-items: center;
}
.menu-tree > .menu-item.selected {
    border-bottom: 2px solid var(--sict-blue);
}
.menu-tree > .menu-item > a {
    text-decoration: none;
    color: #0c0c0c;
    font-weight: 500;
    font-size: 1.4rem;
    white-space: nowrap;
}
.menu-tree > .menu-item.selected > a {
    color: var(--sict-blue);
    font-weight: bold;
}