@import '/src/component/styles/mixin';

.user-page {
    display: grid;
    justify-content: center;
    padding: 2rem 1rem;
}
.user-page > .title {
    width: 100%;
    margin: 0;
    font-size: 2rem;
    font-weight: bold;
    padding-bottom: 1rem;
    border-bottom: 1px solid #c3c3c3;
    margin-bottom: 2rem;
}

.user-page > .table-area {
    overflow: auto;
}
.user-page > .table-area::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}
.user-page > .table-area::-webkit-scrollbar-track {
    background-color: transparent;
}
.user-page > .table-area::-webkit-scrollbar-thumb {
    background-color: #adadad;
    opacity: 0.8;
}
.user-page > .table-area > .user-table {
    width: 1024px;
    border: 1px solid #c0c0c0;
    border-collapse: collapse;
    table-layout: fixed;
}
.user-table > .user-table-body > tr > th{
    background-color: #f0f0f0;
    border: 1px solid #c0c0c0;
    padding: 0.4rem 1rem;
    font-size: 1.4rem;
}
.user-table > .user-table-body > tr > th.longlong {
    width: 20rem;
}
.user-table > .user-table-body > tr > th.long {
    width: 14rem;
}
.user-table > .user-table-body > tr > th.normal {
    width: 12rem;
}
.user-table > .user-table-body > tr > th.short {
    width: 8rem;
}

.user-table > .user-table-body > tr > td{
    font-size: 1.4rem;
    padding: 0.4rem 1rem;
    border: 1px solid #c0c0c0;
    text-align: center;
}

.user-page > .paging-area {
    height: 2rem;
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.user-page > .paging-area > .pages-area {
    margin: 0 1rem;
    display: flex;
    align-items: center;
}
.user-page > .paging-area > .pages-area > .pages {
    text-decoration: none;
    text-align: center;
    color: var(--sict-gray);
    width: 2.1rem;
    font-size: 1.6rem;
    // padding: 0 0.5rem;
}
.user-page > .paging-area > .pages-area > .pages:hover {
    color: var(--sict-deep-gray);
    font-weight: 600;
}
.user-page > .paging-area > .pages-area > .pages.select {
    color: var(--sict-blue);
    font-weight: 600;
}
.user-page > .paging-area > .btn {
    height: 100%;
}
.user-page > .paging-area > .btn:hover {
    cursor: pointer;
    background: #0000002d;
    border-radius: 1rem;
}

@include short-mobile {
    .user-page {
        padding: 0.75rem 1.5rem;
    }
    .user-page > .user-table {
        width: 100%
    }
    .user-table > .user-table-body > tr > th{
        font-size: 1.2rem;
    }
    .user-table > .user-table-body > tr > td{
        font-size: 1.2rem;
    }
    .user-page > .paging-area {
        height: 1.8rem
    }
    .user-page > .paging-area > .pages-area > .pages {
        width: 1.7rem;
        font-size: 1.2rem;
    }
}
@include mobile {
    .user-page {
        padding: 0.75rem 1.5rem;
    }
    .user-page > .user-table {
        width: 100%
    }
    .user-table > .user-table-body > tr > th{
        font-size: 1.2rem;
    }
    .user-table > .user-table-body > tr > td{
        font-size: 1.2rem;
    }
    .user-table > .user-table-body > tr > th.longlong {
        width: 6rem;
    }
    .user-table > .user-table-body > tr > th.long {
        width: 4rem;
    }
    .user-table > .user-table-body > tr > th.normal {
        width: 3rem;
    }
    .user-table > .user-table-body > tr > th.short {
        width: 2rem;
    }
}

@include tablet {
    .user-page > .user-table {
        width: 728px;
    }
    .user-table > .user-table-body > tr > th.longlong {
        width: 12rem;
    }
    .user-table > .user-table-body > tr > th.long {
        width: 8rem;
    }
    .user-table > .user-table-body > tr > th.normal {
        width: 6rem;
    }
    .user-table > .user-table-body > tr > th.short {
        width: 4rem;
    }
}

@include desktop {
}
