@import '/src/component/styles/mixin';

.navi-background {
    position: fixed;
    width: 100%;
    height: 100%;
    background: #0000003d;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    visibility: hidden;
}
.navi-background.open {
    visibility: visible;
}
.navi-area {
    width: 24rem;
    height: 100%;
    box-sizing: border-box;
    background-color: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    position: fixed;
    top: 0;
    left: -24rem;
    user-select: none;
}
.navi-area.open {
    left: 0;
    transition: all ease 0.3s 0s;
}
.navi-area > .header {
    height: 6rem;
    border-bottom: 1px solid #c4c4c4;
    background: #fbfbfb;
    display: flex;
    padding-left: 0.5rem;
    flex-direction: row;
    align-items: center;
}
.navi-area > .header > img {
    padding: 1rem 0.5rem;
    height: 90%;
    width: 5rem;
    object-fit: contain;
    box-sizing: border-box;
}
.navi-area > .header > img:hover {
    cursor: pointer;
}

.navi-area > .menu-area > .menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1rem 0 1rem 1rem;
    height: 3rem;
    transition: all ease 0.3s 0s;
}
.navi-area > .menu-area > .menu:hover {
    cursor: pointer;
    background-color: #2c2c2c52;
}
.navi-area > .menu-area > .menu > img {
    height: 100%;
}
.navi-area > .menu-area > .menu > p {
    margin: 0;
    font-weight: bold;
    margin-left: 1rem;
}


@include short-mobile {
    .navi-area {
        width: 70%;
    }
    .navi-area > .header {
        height: 4rem;
    }
    .navi-area > .header > img {
        padding: 1rem 0.5rem;
        height: 100%;
        width: 4rem;
    }
    .navi-area > .menu-area > .menu {
        transition: none;
    }
}
@include mobile {
    .navi-area {
        width: 70%;
    }
    .navi-area > .header {
        height: 4rem;
    }
    .navi-area > .header > img {
        padding: 0.8rem 0.5rem;
        height: 100%;
        width: 4rem;
    }
    .navi-area > .menu-area > .menu {
        transition: none;
    }
}

@include tablet {
    .navi-area > .header {
        height: 5rem;
    }
    .navi-area > .header > img {
        padding: 1rem 0.5rem;
        height: 95%;
        width: 4rem;
    }
}

@include desktop {
}